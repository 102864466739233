.site-navigation {
	align-items: center;
	background-color: $grey;
	color: $white;
	display: flex;
	flex-direction: column;
	padding: 3.5rem 1.6rem;
	&__nav-items {
		display: flex;
		flex-direction: column;
		margin-right: 2rem;
		width: 100%;
		&:last-of-type {
			margin-right: 0;
		}
		&:hover {
			.site-navigation__vertical-lines {
				width: 100%;
			}
		}
	}
	&__nav-item-link {
		color: $dark;
		font-family: $redhat-bold;
		font-size: 1.6rem;
		letter-spacing: 0.3rem;
	}
	&__vertical-lines {
		background-image: linear-gradient(
			90deg,
			currentColor,
			currentColor 33.33%,
			transparent 33.33%,
			transparent 100%
		);
		background-size: 0.3rem 100%;
		color: $orange-red;
		display: inline-block;
		height: 0.5rem;
		width: 0%;
		transition: all 0.5s ease-in-out;
	}
}
