.resume {
	align-items: center;
	background-color: $fuschia;
	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding-top: 12rem;
	position: relative;
	text-align: center;
	@media screen and (min-width: $large-tablet) {
		flex-direction: row-reverse;
		padding-top: 14rem;
	}
	&__chalk-border-fuschia {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 40rem;
		left: 0;
		position: absolute;
		right: 0;
		top: -15rem;
		z-index: 1;
	}
	&__content-column {
		margin-bottom: 6rem;
		h3 {
			margin-bottom: 1rem;
			@media screen and (min-width: $large-tablet) {
				margin-bottom: $default-margin;
			}
		}
	}
	&__screenshot-column {
		display: block;
		height: auto;
		margin-bottom: 0;
		width: 100%;
	}
	&__subheading-wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media screen and (min-width: $small-mobile) {
			flex-direction: row;
		}
		&__subheading {
			font: 500 2rem/2.6rem $redhat-med;
			font-size: 2rem;
			margin-bottom: 1.5rem;
			margin-right: 0;
			@media screen and (min-width: $small-mobile) {
				margin-bottom: 0;
				margin-right: 1rem;
			}
		}
		&__eyes {
			font-size: 3.4rem;
		}
	}
	&__screenshot-img-wrapper {
		margin: 0 auto;
		max-width: 40rem;
		img {
			box-shadow: $default-box-shadow;
			width: 100%;
		}
	}
}
