.project-card {
	box-shadow: $default-box-shadow;
	height: 100%;
	margin-bottom: 5.5rem;
	width: 100%;
	&:last-of-type {
		margin-bottom: 0;
		@media screen and (min-width: $large-tablet) {
			margin-bottom: 5.5rem;
		}
	}
	@media screen and (min-width: $large-tablet) {
		width: 47%;
	}
	&__img-wrapper {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: block;
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
		&:hover,
		&:active &:focus {
			.project-card__overlay {
				clip-path: ellipse(closest-side farthest-side);
				opacity: 1;
				transform: scale(1.5);
			}
		}
	}
	&__project-img {
		height: auto;
		width: 100%;
	}
	&__overlay {
		align-items: center;
		background-color: $orange;
		bottom: 0;
		clip-path: ellipse(closest-side farthest-side);
		color: $white;
		display: flex;
		flex-direction: column;
		font-size: 0.8rem;
		justify-content: center;
		left: 0;
		line-height: 1rem;
		opacity: 0;
		padding: 0 6rem;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		transform: scale(0);
		transition: all 0.75s ease;
		@media screen and (min-width: $large-mobile) {
			font-size: 1rem;
			line-height: 1.4rem;
			padding: 0 10rem;
		}
		@media screen and (min-width: $tablet) {
			font-size: 1.2rem;
			line-height: 1.6rem;
			padding: 0;
		}
		@media screen and (min-width: $large-tablet) {
			font-size: 0.8rem;
			line-height: 1.2rem;
			padding: 0 6rem;
		}
		@media screen and (min-width: $small-desktop) {
			font-size: 1.2rem;
			line-height: 1.6rem;
			padding: 0 10rem;
		}
		@media screen and (min-width: $desktop) {
			font-size: 1.4rem;
			line-height: 1.8rem;
			padding: 0 12rem;
		}
		@media screen and (min-width: $large-desktop) {
			font-size: 1.6rem;
			line-height: 2rem;
		}
	}
	&__title {
		font-size: 1.2rem;
		line-height: 1.4rem;
		margin-bottom: 1rem;
		@media screen and (min-width: $large-mobile) {
			font-size: 1.4rem;
			line-height: 1.6rem;
			margin-bottom: 2rem;
		}
		@media screen and (min-width: $tablet) {
			font-size: 1.6rem;
			line-height: 1.8rem;
			margin-bottom: $default-margin;
		}
		@media screen and (min-width: $large-tablet) {
			font-size: 1.2rem;
			line-height: 1.4rem;
			margin-bottom: 1rem;
		}
		@media screen and (min-width: $small-desktop) {
			font-size: 1.6rem;
			line-height: 1.8rem;
			margin-bottom: 2.5rem;
		}
		@media screen and (min-width: $desktop) {
			font-size: 1.8rem;
			margin-bottom: $default-margin;
		}
		@media screen and (min-width: $large-desktop) {
			font-size: 2rem;
		}
	}
}
