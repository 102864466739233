@import "../abstracts/variables";

@font-face {
	font-family: "Blastula";
	src: url("../../fonts/blastula-reg.ttf") format("truetype");
}
@font-face {
	font-family: "Mandala";
	src: url("../../fonts/mandala.ttf") format("truetype");
}
@font-face {
	font-family: "Redhat Reg";
	src: url("../../fonts/redhat-reg.ttf") format("truetype");
}
@font-face {
	font-family: "Redhat Med";
	src: url("../../fonts/redhat-med.ttf") format("truetype");
}
@font-face {
	font-family: "Redhat Bold";
	src: url("../../fonts/redhat-bold.ttf") format("truetype");
}

$bandar: "Bandar", sans-serif;
$blastula: "Blastula", serif;
$mandala: "Mandala";
$redhat-reg: "Redhat Reg", sans-serif;
$redhat-med: "Redhat Med", sans-serif;
$redhat-bold: "Redhat Bold", sans-serif;
$fontawesomeBrands: "Font Awesome 6 Brands";
$fontawesome: "Font Awesome 6 Free";

// Font Weights
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

body {
	font-family: $redhat-reg;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $blastula;
	font-weight: $bold;
	margin: 0;
}

h1,
.h1 {
	font-size: 4.5rem;
	@media screen and (min-width: $large-tablet) {
		font-size: 5rem;
	}
}

h2,
.h2 {
	font-size: 3.5rem;
	@media screen and (min-width: $large-tablet) {
		font-size: 4rem;
	}
}

h3,
.h3 {
	font-size: 2.5rem;
	@media screen and (min-width: $large-tablet) {
		font-size: 3rem;
	}
}

h4,
.h4 {
	font-size: 2rem;
	@media screen and (min-width: $large-tablet) {
		font-size: 2.5rem;
	}
}

h5,
.h5 {
	font-size: 1.8rem;
	@media screen and (min-width: $large-tablet) {
	}
	font-size: 2rem;
}

h6,
.h6 {
	font-size: 1.6rem;
	@media screen and (min-width: $large-tablet) {
		font-size: 1.8rem;
	}
}

p,
span {
	font: 400 1.6rem/2.2rem $redhat-reg;
	letter-spacing: 0.3px;
	@media screen and (min-width: $large-tablet) {
		font: 400 1.8rem/2.4rem $redhat-reg;
	}
}

a {
	font: 400 1.6rem/2.2rem $redhat-reg;
	letter-spacing: 0.3px;
}
