.button {
	background-color: $orange-red;
	border: 0 solid $grey;
	box-sizing: border-box;
	color: $white;
	display: flex;
	font-family: $redhat-reg;
	font-size: 1.2rem;
	font-weight: 700;
	justify-content: center;
	line-height: 1.75rem;
	padding: 0.75rem 1.65rem;
	position: relative;
	text-align: center;
	text-decoration: none $dark solid;
	text-decoration-thickness: auto;
	position: relative;
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	&:focus {
		outline: 0;
	}
	&:after {
		content: "";
		position: absolute;
		border: 0.1rem solid $dark;
		bottom: 0.4rem;
		left: 0.4rem;
		width: calc(100% - 0.1rem);
		height: calc(100% - 0.1rem);
	}
	&:hover {
		&:after {
			bottom: 0.2rem;
			left: 0.2rem;
		}
	}
	@media screen and (min-width: $tablet) {
		padding: 1.75rem 4rem;
		font-size: 1.6rem;
	}
}
