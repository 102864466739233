.footer {
	background-color: $fuschia;
	color: $white;
	position: relative;
	@media screen and (min-width: $large-tablet) {
		padding: 6rem 10.5rem $default-padding;
	}
	h1 {
		text-align: center;
	}
	&__content-wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media screen and (min-width: $large-mobile) {
			flex-direction: row;
		}
		p {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 100%;
			@media screen and (min-width: $large-mobile) {
				width: unset;
			}
			i {
				margin-right: 1rem;
			}
		}
		span {
			display: block;
		}
	}
	&__nav {
		display: flex;
		justify-content: center;
		position: relative;
		margin-bottom: 2rem;
		z-index: 2;
		@media screen and (min-width: $large-mobile) {
			margin-bottom: 0;
		}
		ul {
			align-items: center;
			li {
				margin-right: 1.5rem;
				a {
					color: $white;
					display: block;
					height: 100%;
				}
				i {
					&::before {
						font-size: 2.4rem;
						@media screen and (min-width: $small-mobile) {
							font-size: 3rem;
						}
					}
					&.fa-brands {
						&::before {
							font-family: $fontawesomeBrands;
						}
					}
					&.fa-regular,
					&.fa-solid {
						&::before {
							font-family: $fontawesome;
						}
					}
				}
				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	.page-nav-wrapper {
		animation: animate-arrow 1.5s linear infinite;
		bottom: 14rem;
		cursor: pointer;
		display: none;
		height: 4.5rem;
		position: absolute;
		right: 1rem;
		@media screen and (min-width: $large-mobile) {
			display: block;
		}
		@media screen and (min-width: $large-tablet) {
			bottom: 10rem;
		}

		img {
			height: 100%;
			transform: rotate(-76deg);
		}
	}
}

@keyframes animate-arrow {
	0%,
	100% {
		transform: translate(0, 0);
	}
	25%,
	75% {
		transform: translate(0, -5px);
	}
	50% {
		transform: translate(0, -10px);
	}
}
