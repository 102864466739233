.container {
	display: flex;
	justify-content: space-evenly;
	margin: 4rem 0;
	width: 100%;
}

.column {
	width: 100%;
	@media screen and (min-width: $large-tablet) {
		width: 45%;
	}
}

.section {
	margin: 0 auto;
	padding: 4rem 2rem;
	@media screen and (min-width: $small-mobile) {
		padding: 4rem 2rem 6rem;
	}
	@media screen and (min-width: $large-tablet) {
		padding: 6rem 10.5rem 8rem;
	}
}
